#quickViewModal{
    .modal-body{
        padding: 10px;
    }
}
.modal{
    .modal-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;

       .modal-body{   
           .pro-description{
            position: relative;
            bottom: 0;
            padding-right: 0;
            .badges{
                position: relative;
                top: auto;
                left: auto;
                right: auto;
            }
           .pro-title{
            line-height: 1.5;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
           }
               .pro-infos{
                   margin-top: 10px;
                   .pro-catgory{
                       display: inline-block;
                   }
                   .pro-single-info{
                       display: flex;
                       line-height: 24px;


                       b{
                           font-size: $font-size-base;
                           font-weight: $font-weight-bolder;
                           color:$body-color;
                           margin-right: 10px;
                       }

                       ul{
                           display: inline-block;
                           padding-left: 0;
                           margin-bottom: 0;

                           li{
                               display: inline-block;

                               &:after{
                                   content: ",";
                               }
                           }
                       }
                   }
                   
               }
               .pro-counter {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .popup-detail-info{
                line-height: 1.5;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                div , p{
                    color:$body-color-secondary;
                    margin-top: 10px;
                    padding: 0;
                 
                    line-height: 1.5;
                     white-space: normal;
                     overflow: hidden;
                     text-overflow: ellipsis;
                     display: -webkit-box;
                     -webkit-line-clamp: 2;
                     -webkit-box-orient: vertical;
                }
            }
             .pro-options{
                 display: flex;
                 justify-content: space-between;
                 .box{
                    display: flex;
                    align-items: center;
                    label{
                        margin-bottom: 0;
                        margin-right: 5px;
                    }
                }
             }
               .pro-price {
                   display: flex;
                   align-items: flex-end;
   
                   ins{
                    font-family: $font-family-base-bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: ($font-size-lg + 0.6);
                    font-weight: $font-weight-bolder;
                    color: $secondary;
                    text-decoration: none;
                    line-height: 1;
                   }
       
                   del {
                            font-family: $font-family-base-bold;
                            color: $body-color-secondary;
                            text-decoration: line-through;
                            margin-left: 10px;
                            font-size: $font-size-base;
                          
                   }
               }
               
           }

     
       }
    }
    .carousel{
       
       .carousel-inner{
           .carousel-item{
             
               background-color: $light;
               text-align: center;
               a{
                   position: absolute;
                   bottom: 10px;
                   right: 10px;
                   font-size: 18px;
                   color: darken($color: #e1e1e1, $amount: 10%);
               }

              
           }
       }
    //    .carousel-control-prev,
    //    .carousel-control-next{
    //        background-color: $primary;
    //        height: 40px;
    //        width: 40px;
    //        top: 50%;
    //        margin-top: -20px;

    //        .fas{
    //            font-size: 18px;
    //        }
    //    }
       .carousel-control-prev,
       .carousel-control-next{
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $body-bg-secondary !important;
        color: color-yiq($body-bg-secondary) !important;
        border:none !important;
        border-radius: 0;
        height: 38px;
        width: 38px;
        text-align: center;
        line-height: 38px;
        text-decoration: none;
        outline: none;
        box-shadow: none;
        position: absolute;
        top: 50%;
     .fas{
         color: color-yiq($body-bg-secondary);
     }
        &:hover{
            background-color:$secondary;
        }
        &::after {
            content: "";
            background-color: darken($secondary, 10%) ;
            opacity: 0;
        }
    }
    .carousel-control-prev{
        transition: 1.4s ease-in-out;
        transform: translateX(-30px) !important;
        -webkit-transform: translateX(-30px) !important;
        overflow: hidden;
        opacity: 0;
        left: 15px;

      
    }
    .carousel-control-next{
        transition: 1.2s ease-in-out;
        transform: translateX(20px) !important;
        -webkit-transform: translateX(20px) !important;
        overflow: hidden;
        opacity: 0;
        right:15px;
      
    }
    &:hover{
        
        .carousel-control-prev{
            transition: 0.4s ease-in-out;
            transform :translateX(0px) !important;
            -webkit-transform: translateX(0px) !important;
            opacity: 1 !important;
            overflow: hidden;
            box-shadow: none;
            .fas{
                color: color-yiq($secondary);
            }
        }
        .carousel-control-next{
            transition: 0.4s ease-in-out;
            transform :translateX(0px) !important;
            -webkit-transform: translateX(0px) !important;
            opacity: 1 !important;
            box-shadow: none;
            overflow: hidden;
            .fas{
                color: color-yiq($secondary);
            }
        }
    }
    }

}
.carousel-control-prev:hover:after, 
.carousel-control-prev:focus:after, 
.carousel-control-prev:active:after,
.carousel-control-next:hover:after, 
.carousel-control-next:focus:after, 
.carousel-control-next:active:after{
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1 !important;
    box-shadow: none;
}  
a.carousel-control-prev.btn-secondary.swipe-to-top:focus {
    box-shadow: none !important;
}
a.carousel-control-next.btn-secondary.swipe-to-top:focus {
    box-shadow: none !important;
}

@media only screen and (max-width:576px){
    .modal .modal-dialog .modal-body .pro-description .pro-counter {
        display: flex;
        align-items: self-start;
        flex-direction: column;
        .item-quantity{
            margin-bottom: 15px;
        }
    }
}
@media only screen and (max-width:767px){
    .modal .modal-dialog .modal-body .pro-description .pro-counter{
        margin-bottom: 15px;
    }
    #newsletterModal .modal-body .promo-box {
        padding: 30px 0 30px 15px;}
}
