.checkout-area{
  
   
    .checkout-module{
        // background-color: $white;
        padding: 15px;
        padding-top: 0;
        width: 100%;
        
        .checkoutd-nav{
            padding-left: 0;
            padding-right: 0;
    
            .nav-item{
                font-family: $font-family-base-bold;
                width: calc(25% - 17px);
                margin-right: 15px;
            }
            .nav-item a{
             
                background-color: #e1e1e1;
              
                padding: 5px;
                padding-left: 35px;
                font-size: 15px;
                font-weight: $font-weight-bold;
                position: relative;
                padding-left: 30px;
                padding-top: 8px;
                padding-bottom: 8px;
            }
            .nav-item a:after {
                content: "";  
                border-top: 19.7px solid transparent;
                border-bottom: 19.7px solid transparent;
                border-left: 18px solid #e1e1e1;
                position: absolute;
                right: -18px;
                top: 0px;
                z-index: 1;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }
            .nav-item a:before {
                content: "";  
                border-top: 19.7px  solid transparent;
                border-bottom: 19.7px  solid transparent;
                border-left: 18px  solid  $body-bg;
                position: absolute; left: 0; top: 0;
                transition: 0.4s;
            }
            .nav-item a.active{
                background-color: $secondary;
            }
            .nav-item a.active:after {
                content: "";  
                border-top: 19.7px solid transparent;
                border-bottom: 19.7px solid transparent;
                border-left: 18px solid $secondary;
                position: absolute;
                 right: -18px; top: 0;
                z-index: 1;
                transition: 0.4s;
            }
            .nav-item a.active:before {
                content: "";  
                border-top:19.7px  solid transparent;
                border-bottom: 19.7px  solid transparent;
                border-left:18px  solid  $body-bg;
                position: absolute; left: 0; top: 0;
                transition: 0.4s;
            }
        }
        
        .form-group{
            display: inline-block;
            width: 49.6%;
            padding: 0 15px;
            padding-left:0;

            
            .select-control{
                position: relative;
                .form-control{
                    border: 1px solid #ced4da !important;
                }

                &::before{
                    font-family:"Font Awesome 5 Free";
                    font-weight: 900; /* regular style/weight */
                    content: "\f0d7";
                    position: absolute;
                    top: 7px;
                    right: 15px;
                    color: #ddd;
                    z-index: 0;
                }
            }

            .form-check-inline{

                margin-right: 5px;
                background-color: white;
                height: 34px;
                padding-left: 10px;
                padding-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                float: left;
            }
        }
        .btn{
            margin-right: 10px;
           
        }
    }

    .right-table{
    
            margin: 0;
            thead{
                tr {
                    th{
                        text-transform: uppercase;
                        border: none;
                        font-family: $font-family-base-bold;
                    }
                    th[align="center"]{
                        text-align: center;
                    }
                }
            }
    
        background-color: $white;
        border: 2px solid $border-color;
        margin-bottom: 30px;
        tbody{
            th,td{
                border-top: none;
                padding: 15px 10px;
            }
        }
   
    }
    .item{
        position: relative;
        float: left;
        width: 100%;

        .item-thumb{
            float: left;
            width: 120px;
            height: 120px;
            border:1px solid $gray-300;
            margin-right: 15px;
        }
        
        .item-detail{
            float: left;

            h4{
                font-size: $font-size-lg;
                small{
                    color: $body-color-secondary;
                }
            }

            .item-attributes{

            }
            .item-controls{
                position: absolute;
                bottom: 0;
                .btn{
                    font-size: 12px;
                    border:1px solid $gray-300;
                    padding: 3px 4px 2px 4px;
                    text-align: center;
                    height: 20px;
                    width: 20px;
                    .fas{
                        justify-content: center;
                    }
                }
            }
        }
        
    }
    .item-price{
        color: $secondary;
    }
    .item-price, .item-total{
        font-size: 1.25rem;
        font-weight: $font-weight-bold;
    } 

    .tab-content{
        .tab-pane{
            .top-table{
                thead{
                    background-color: $white;
                    tr{
                        th{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        }
                    }
                }

                tbody{
                
                    border-bottom: 2px solid $gray-400;
                    tr{
                        background-color: $white;
                        td.item-detail-left{
                            text-align: right;
                            justify-content: flex-start;
                        }
                        td{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .item-detail{
                                
                                h4{
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                    
                }
            }  
        }
    }
}