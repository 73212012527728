// ....compare....

.wishlist-content{
    margin-bottom: 30px;
    
 
    .h5-heading{
        margin: 0;
    }
 
    .media-main{
        background-color: white;
        .media{
            
            padding: 20px;
            img{
                width: 140px;
                height: 160px;
                border:$border-width solid #ddd;
                margin-right: 1rem;
            }
        }
        .media-body{
            .texting{
                h3{
                    font-family: $font-family-base-bold;
                    font-size: $font-size-lg;

                }
                .price{
                    white-space: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    margin-bottom: 5px;
                    font-family: $font-family-base-bold;
                    font-size: $font-size-lg;
                    margin-bottom: 5px;
                    span{
                        color: $danger;
                      
                    }
                }
                p{
                    white-space: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    margin-bottom: 10px;
                }
                .buttons{
                    display: flex;

                    .input-group{
                        margin-right: 5px;
                    }
                }
                    
            }
            .detail{
                text-align: right;
                p{
                    margin-bottom: 5px;
                    color:#a1abb6;
                }
                .share{
                    margin-bottom: 5px;
                   
                
                }
    
            }
        }
       
    }
  
}
              