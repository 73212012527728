.animation-s1{
	
	/* Zoom In #1 */
	.group-banners{
		.banner-image img {
			-webkit-transform: scale(1);
			transform: scale(1);
			-webkit-transition: .8s ease-in-out;
			transition: .8s ease-in-out;
		}
		.banner-image:hover img {
			-webkit-transform: scale(1.1);
			transform: scale(1.1);
		}
	}
	.categories-content{
		.categories-image{
			overflow: hidden;
			-webkit-transform: scale(1);
			transform: scale(1);
			-webkit-transition: .8s ease-in-out;
			transition: .8s ease-in-out;
		
			}
		.categories-image:hover img {
			-webkit-transform: scale(1.1);
			transform: scale(1.1);
			-webkit-transition: .8s ease-in-out;
			transition: .8s ease-in-out;
		}
	}
	.pro-main{
		
		.banner-image img {
			-webkit-transform: scale(1);
			transform: scale(1);
			-webkit-transition: .8s ease-in-out;
			transition: .8s ease-in-out;
		}
		.banner-image:hover img {
			-webkit-transform: scale(1.1);
			transform: scale(1.1);
		}
	}

	.banner-category img {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transition: .8s ease-in-out;
		transition: .8s ease-in-out;
	}
	.banner-category:hover img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}

	.full-width-banner{
		.banner-image img {
			-webkit-transform: scale(1);
			transform: scale(1);
			-webkit-transition: .3s ease-in-out;
			transition: .3s ease-in-out;
		}
		.banner-image:hover img {
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
	}
	
	.full-screen-banner{
		.banner-image img {
			-webkit-transform: scale(1);
			transform: scale(1);
			-webkit-transition: .3s ease-in-out;
			transition: .3s ease-in-out;
		}
		.banner-image:hover img {
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
	}
	.cat-thumb{
		overflow: hidden;
		position: relative;
	}
	.cat-thumb img {
		-webkit-transform: scale(1);
			transform: scale(1);
			-webkit-transition: .3s ease-in-out;
			transition: .3s ease-in-out;
	}
	.cat-thumb:hover img {
		-webkit-transform: scale(1.2);
			transform: scale(1.2);
	}
		
}

.animation-s2{
	/* Flashing */

	.group-banners{
		.banner-image:hover img {
			opacity: 1;
			-webkit-animation: flash 1.5s;
			animation: flash 1.5s;
		}
		
	}
	.categories-content{
	
		.categories-image:hover img {
			opacity: 1;
			-webkit-animation: flash 1.5s;
			animation: flash 1.5s;
		}
	}

	.full-width-banner{
		.banner-image:hover img {
			opacity: 1;
			-webkit-animation: flash 1.5s;
			animation: flash 1.5s;
		}
		
	}
	.full-screen-banner{
		.banner-image:hover img {
			opacity: 1;
			-webkit-animation: flash 1.5s;
			animation: flash 1.5s;
		}
		
	}

	.cat-thumb:hover img {
		opacity: 1;
		-webkit-animation: flash 1.5s;
		animation: flash 1.5s;
	}
	
	
	 

}

@-webkit-keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}
@keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}

.animation-s3{
	/* Shine */
	
	.group-banners{
		.banner-image{
			position: relative;
		}
		.banner-image::before {
			position: absolute;
			top: 0;
			left: -95%;
			z-index: 2;
			display: block;
			content: '';
			width: 50%;
			height: 100%;
			background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			-webkit-transform: skewX(-25deg);
			transform: skewX(-25deg);
		}
		.banner-image:hover::before {
			-webkit-animation: shine .95s;
			animation: shine .95s;
		}
		
		
	}
	.categories-content{
		.categories-image{
			position: relative;
		}
		.categories-image::before {
			position: absolute;
			top: 0;
			left: -75%;
			z-index: 2;
			display: block;
			content: '';
			width: 50%;
			height: 100%;
			background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			-webkit-transform: skewX(-25deg);
			transform: skewX(-25deg);
		}
	
		.categories-image:hover::before {
			-webkit-animation: shine .75s;
			animation: shine .75s;
		}
	}

	.full-width-banner{
		.banner-image{
			position: relative;
		}
		.banner-image::before {
			position: absolute;
			top: 0;
			left: -75%;
			z-index: 2;
			display: block;
			content: '';
			width: 50%;
			height: 100%;
			background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			-webkit-transform: skewX(-25deg);
			transform: skewX(-25deg);
		}
		.banner-image:hover::before {
			-webkit-animation: shine .75s;
			animation: shine .75s;
		}
		
	}
	.full-screen-banner{
		.banner-image{
			position: relative;
		}
		.banner-image::before {
			position: absolute;
			top: 0;
			left: -75%;
			z-index: 2;
			display: block;
			content: '';
			width: 50%;
			height: 100%;
			background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			-webkit-transform: skewX(-25deg);
			transform: skewX(-25deg);
		}
		.banner-image:hover::before {
			-webkit-animation: shine .75s;
			animation: shine .75s;
		}
		
	}

	.cat-thumb{
		overflow: hidden;
		position: relative;
	}
	.cat-thumb::before {
		position: absolute;
		top: 0;
		left: -75%;
		z-index: 2;
		display: block;
		content: '';
		width: 50%;
		height: 100%;
		background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
		background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
		-webkit-transform: skewX(-25deg);
		transform: skewX(-25deg);
	}
	.cat-thumb:hover::before {
		-webkit-animation: shine .75s;
		animation: shine .75s;
	}
	

}

@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}


.animation-s5{
	/* Opacity #2 */

	.group-banners{
		.banner-image img {
			opacity: 1;
			-webkit-transition: .3s ease-in-out;
			transition: .3s ease-in-out;
		}
		.banner-image:hover img {
			opacity: .5;
		}
		
	}
	.categories-content{
	.categories-image img{
		opacity: 1;
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
		.categories-image:hover img {
			opacity: .5;
		}
	}
	.full-width-banner{
		.banner-image img {
			opacity: 1;
			-webkit-transition: .3s ease-in-out;
			transition: .3s ease-in-out;
		}
		.banner-image:hover img {
			opacity: .5;
		}
		
	}
	.full-screen-banner{
		.banner-image img {
			opacity: 1;
			-webkit-transition: .3s ease-in-out;
			transition: .3s ease-in-out;
		}
		.banner-image:hover img {
			opacity: .5;
		}
		
	}

	.cat-thumb img {
		opacity: 1;
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
	.cat-thumb:hover img {
		opacity: .5;
	}
	

}


.animation-s4{
	/* Circle */

	.group-banners{
		.banner-image {
			position: relative;
		}
		.banner-image::before {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			display: block;
			content: '';
			width: 0;
			height: 0;
			background: rgba(255,255,255,.2);
			border-radius: 100%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			opacity: 0;
		}
		.banner-image:hover::before {
			-webkit-animation: circle 1s;
			animation: circle 1s;
		}
		
		
	}
	.categories-content{
		.categories-image{
			position: relative;
		}
		.categories-image::before {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			display: block;
			content: '';
			width: 0;
			height: 0;
			background: rgba(255,255,255,.2);
			border-radius: 100%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			opacity: 0;
		}
		.categories-image:hover::before {
			-webkit-animation: circle .75s;
			animation: circle .75s;
		}
		}
	.full-width-banner{
		.banner-image {
			position: relative;
		}
		.banner-image::before {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			display: block;
			content: '';
			width: 0;
			height: 0;
			background: rgba(255,255,255,.2);
			border-radius: 100%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			opacity: 0;
		}
		.banner-image:hover::before {
			-webkit-animation: circle .75s;
			animation: circle .75s;
		}
	}
	.full-screen-banner{
		.banner-image {
			position: relative;
		}
		.banner-image::before {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			display: block;
			content: '';
			width: 0;
			height: 0;
			background: rgba(255,255,255,.2);
			border-radius: 100%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			opacity: 0;
		}
		.banner-image:hover::before {
			-webkit-animation: circle .75s;
			animation: circle .75s;
		}
	}

	.cat-thumb {
		position: relative;
		overflow: hidden;
	}
	.cat-thumb::before {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		display: block;
		content: '';
		width: 0;
		height: 0;
		background: rgba(255,255,255,.2);
		border-radius: 100%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		opacity: 0;
	}
	.cat-thumb:hover::before {
		-webkit-animation: circle .75s;
		animation: circle .75s;
	}
	
}


@-webkit-keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}
@keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}
@keyframes animationmenu{from{opacity:0;
filter: alpha(opacity=0);
transform: translate(0px,-10px);}
					to{
						opacity:1;
						filter: alpha(opacity=100);
						transform: translate(0px,0px);
					
					}
  }

    
  @keyframes animationselect{
	  from{
		  opacity:0;
		  filter: alpha(opacity=0);
		  transform: translate(0px,-20px);
		}
				to{
					opacity:1;
					filter: alpha(opacity=100);
					transform: translate(0,50px);
				
				}
	  }
	
  @keyframes animationcartmenu{
	  from{
		    opacity:0;
			filter: alpha(opacity=0);
			top: -10px;
		}
		to{
			opacity:1;
			filter: alpha(opacity=100);
			top: 0;
		
		}
	  }

@keyframes fadeIn{from{opacity:0}to{opacity:1}}

@keyframes imageScroll{
    0%{-webkit-transform:perspective(400px) rotate3d(0,1,0,90deg);
        transform:perspective(400px) rotate3d(0,1,0,90deg);
        -webkit-animation-timing-function:ease-in;
        animation-timing-function:ease-in;opacity:0}
    40%{-webkit-transform:perspective(400px) rotate3d(0,1,0,-20deg);
        transform:perspective(400px) rotate3d(0,1,0,-20deg);
        -webkit-animation-timing-function:ease-in;animation-timing-function:ease-in}
    60%{-webkit-transform:perspective(400px) rotate3d(0,1,0,10deg);
        transform:perspective(400px) rotate3d(0,1,0,10deg);opacity:1}
    80%{-webkit-transform:perspective(400px) rotate3d(0,1,0,-5deg);
        transform:perspective(400px) rotate3d(0,1,0,-5deg)}
    100%{-webkit-transform:perspective(400px);transform:perspective(400px)
    }
}


// blink
@keyframes blinker {
	from {opacity: 1.0;}
	to {opacity: 0.0;}
  }
// bottomtotop


@keyframes bounce {
	0%,
	100%,
	20%,
	50%,
	80% {
	  -webkit-transform: translateY(0);
	  -ms-transform: translateY(0);
	  transform: translateY(0);
	}
	40% {
	  -webkit-transform: translateY(-10px);
	  -ms-transform: translateY(-10px);
	  transform: translateY(-10px);
	}
	60% {
	  -webkit-transform: translateY(-5px);
	  -ms-transform: translateY(-5px);
	  transform: translateY(-5px);
	}
  }
  @keyframes slidedown {
	0%{
		transform: translateY(-24rem);
		transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
		transform-origin: top bottom;
	}
	100%,
	20%,
	50%,
	80% {
	  -webkit-transform: translateY(0);
	  -ms-transform: translateY(0);
	  transform: translateY(0);
	}
	
  }


  
//  header slide down fixed
@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    } 
    100% {
        opacity: 0.9;
        transform: translateY(0);
    } 
}



.aa{
	opacity: 1;
	transform: translateZ(0);
	transition-timing-function: ease;
	transition-duration: .4s;
	transition-property: opacity,transform;
}


/* newsletter animate */
@-webkit-keyframes news-ani {
	from {top: 0px;}
	to {top: 50%;}
  }
  
  @keyframes news-ani {
	from {top: 0px;}
	to {top: 50%;}
  }






/* Animation */

@keyframes fadeInUp {
    from {
        transform: translateY(40px)
    }

    to {
        transform: translateY(0px);
        opacity: 1
    }
}


.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
	opacity: 0;
	transform: translateY(40px);
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}



@keyframes Animationlookbook{
	from{
		box-shadow:0 0 0 0 #d4d4d4
	}
	to{
		box-shadow:0 0 0 3px rgba(255,255,255,.3)
	}
	}