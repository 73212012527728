// ...........footer.............
$bg-footer:  #f5f5f5;
$bg-copyright:#dbdbdb;

$search-content: $primary;

  
.footer-eight{
    padding-top: 0;
    padding-bottom: 0;
    background-color: $bg-footer;
    color: color-yiq($bg-footer);
    
    .search-content{
        background-color: $secondary;
        color: color-yiq($secondary);
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 45px;
        .newsletter {
            // margin-bottom: 30px;    
            display: flex;
            justify-content: center;
            align-items: center;
           
            .block {
                position: relative;
                margin-left: 14px;  
                width: 502px;
                .search-field-module {
                    position: relative;
                    background-color: $white;
                    border:1px solid $border-color;
                    box-shadow: none;
                    width: 100%;
                    &:focus{
                        border-color: $secondary;
                    }
                    
                        .search-field-wrap{
                            float: right;
                            height: 42px;
                            width: 100%;
                            input {
                                float: left;
                                padding: 0px 50px 0 15px;
                                height: 42px;
                                width: 100%;
                                border-radius: 0;
                                border: none;
                                outline: none !important;
            
                                
                            }
                            .btn-secondary {
                                position: absolute;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                height: 100%;
                             
                            } 
                       }   
                    .search-field-select{
                        float: left;
                        position: relative;
                        width: 160px !important;
                        height: 50px;
        
                        .bootstrap-select{
                            width: 100% !important;
                            .btn.dropdown-toggle{
                                background-color:$white;
                                color: $body-color-secondary;
                                font-weight: $font-weight-normal;
                                font-family: $font-family-base;
                                height: 50px;
                                width: 100%;
                                border: none;
                                padding: 0;
                                // padding: 0.6rem 4px 0.6rem 15px;
                                .filter-option{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                                .filter-option-inner-inner {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
            
                                &:focus{
                                    outline: none !important;
                                }
            
                                &::after{
                                    font-family:"Font Awesome 5 Free";
                                    font-weight: 900; /* regular style/weight */
                                    content: "\f107";
                                    position: absolute;
                                    top: 17px;
                                    right: 6px;
                                    color: $body-color-secondary;
                                    border: none;
                                }
                                
                            }
                            
                     
                            
                        }
                        &::before {
                            content: "";
                            width: 1px;
                            height: 25px;
                            background: $border-color;
                            position: absolute;
                            top: 50%;
                            margin-top: -12px;
                            right: -14px;
                            z-index: 1;
                        }
                    }
                //    .search-field-wrap{
                //         float: left;
                //         height: 50px;
                //         width: calc(100% - 160px);
                //         input {
                //             float: left;
                //             padding: 0px 50px 0 32px;
                //             height: 50px;
                //             width: 100%;
                //             border-radius: 0;
                //             border: none;
                //             outline: none !important;
        
                            
                //         }
                //         .btn-secondary {
                //             position: absolute;
                //             right: 0;
                //             top: 0;
                //             bottom: 0;
                //             height: 100%;
                //             width: 52px;
                //             padding: 0;
                //         } 
                //    }         
                }
                
            }
        }
    }  
    
    h5 {
        font-size: $font-size-lg;
        text-transform: uppercase;
        margin: 0;
    } 
    hr {
        margin-top: 5px;
        margin-bottom: 1rem;
        border: 0;
        border-top: $border-width solid $border-color;
        &:before {
            height: 1px;
            background-color: $secondary;
            content: "";
            position: absolute;
            width: 85px;
            margin-top: -1px;
        }
    }     
    ul{
        li{
            a{
                .fa{
                    padding-right: 5px;
                }
                &:hover{
                    color: $secondary;
                }
            }
        }
    }   
    .footer-padding{
        padding-top: 15px;
    }
    .contacts{   
        background-color: lighten($secondary, 15%);
        padding-top: 15px;
        padding-bottom: 30px;
        padding-left: 10px;

        h5{
            color:color-yiq($secondary);
        }
        hr {
            margin-top: 5px;
            margin-bottom: 1rem;
            border: 0;
            border-top: $border-width solid color-yiq($secondary);
            &:before {
                height: 1px;
                background-color:$secondary;
                content: "";
                position: absolute;
                width: 85px;
                margin-top: -1px;
            }
        }       
        .contact-list  {
            li {
                display: flex;
                align-items: center;
                list-style: none;
                font-size: $font-size-base;
                margin-bottom: 15px;
                color: color-yiq($secondary);

                .fas {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: $font-size-lg;
                    color: color-yiq($secondary);
                    height: 30px;
                    width: 30px;
                }
                a {
                    color: color-yiq($secondary);
                }
            }
        }
    }
    .links-list{
        li{
            list-style-type: none;
            margin-bottom: 8px;
            a{
                display: block;
                font-size: $font-size-base;
                color: color-yiq($bg-footer);
                &:hover {
                    color: $secondary;
                }
            }
        }
    }    
    
    // -----------------social
    .socials {
        display: block;
        .list {
            display: block;
            padding-left: 0;
            margin-bottom: 0;
            li{
                display: inline-block;
                a {
                    background-color:$search-content;
                    color: color-yiq($search-content);
                    font-size: $font-size-lg;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    border-radius: $border-radius;
                    border: $border-width solid $border-color;

                    &:hover
                        {
                        background-color: $secondary;
                        color: color-yiq($secondary);
                        border-color: $secondary;
                    }
                }
            }
        }  
        .footer-image{
            margin-top: 2rem;
        } 
    }

.copyright-content{
    background-color: $bg-copyright;
    color: color-yiq($bg-copyright);
    padding-top: 10px;
    padding-bottom: 10px;

    .footer-image {
        .img-fluid{
            height: 20px;
        }
    }

    .footer-info{
        text-align: center;
        a{
            color:color-yiq($bg-copyright);
        }
    }
}  
}