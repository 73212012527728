// ...........footer.............
$bg-footer: $primary;
$bg-copyright: $primary-dark;

.footer-two {
    padding-top: 45px;
    background-color: $bg-footer;
    color: color-yiq($bg-footer);

    .single-footer {
        h5 {
            font-size: $font-size-lg;
            text-transform: uppercase;
            margin: 0;
        } 
        hr {
            margin-top: 5px;
            margin-bottom: 1rem;
            border: 0;
            border-top: $border-width solid $border-color;
            &:before {
                height: 1px;
                background-color: $secondary;
                content: "";
                position: absolute;
                width: 85px;
                margin-top: -1px;
            }
        }     
        ul{
            li{
                a{
                    .fa{
                        padding-right: 5px;
                    }
                    &:hover{
                        color: $secondary;
                    }
                }
            }
        }        
        .contact-list  {
            li {
                display: flex;
                align-items: center;
                list-style: none;
                font-size: $font-size-base;
                margin-bottom: 15px;

                .fas {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    font-size: $font-size-lg;
                    
                    color: $secondary;
                    height: 30px;
                    width: 30px;
                }
                a {
                    color: color-yiq($bg-footer);;
                }
            }
        }
        .links-list{
            li{
                list-style-type: none;
                margin-bottom: 8px;
                a{
                    display: block;
                    font-size: $font-size-base;
                    color: color-yiq($bg-footer);;
                    &:hover {
                        color: $secondary;
                    }
                }
            }
        }
               
               
        .newsletter {
            margin-bottom: 35px;
            .block {
                margin-bottom: 15px;
                position: relative;
                .form-inline {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    width: 100%;
                    margin-left: auto;
                    .search {
                        background-color: $white;
                        border-radius: $border-radius;
            
                        position: relative;
                        width: 100%;
                        
                        .select-control{
                            float: left;
                            position: relative;
                            border-right: $border-width solid $border-color;
                            width: 159px;
                            height: 40px;
            
                            .form-control{
                                height: 40px;
                                width: 100%;
                            }
                            &::before{
                                font-family:"Font Awesome 5 Free";
                                font-weight: 900; /* regular style/weight */
                                content: "\f0d7";
                                position: absolute;
                                top: 10px;
                                right: 15px;
                                color: $body-color;
                            }
                        }
                        input {
                            float: right;
                            height: 40px;
                            padding: 8px 98px 8px 8px;
                            width: 100%;
                            border-radius: 0;
                            border: none;
                            outline: none !important;
                            border: $border-width solid $border-color;
                        }
                        .btn-secondary {
                            position: absolute;
                            right: 1px;
                            top: 1px;
                            bottom: 1px;
                            height: calc(100% - 2px);
                            width: 90px;
                            padding-left: 0;
                            padding-right: 0;
                            font-size: 13px;
                            display: none;
                        }
                        .fa-location-arrow {
                            display: block;
                            position: absolute;
                            right: 1px;
                            top: 1px;
                            bottom: 1px;
                            height: calc(100% - 2px);
                            width: 46px;
                            padding: 0px 0px;
                            border-radius: 0;
                            box-shadow: none;
                            outline: none;
                            border: none;
                            
                        }               
                      
                    }
                }
            }
        }
         
        // -----------------social
        .socials {
            display: block;
            .list {
                display: block;
                padding-left: 0;
                margin-bottom: 0;
                li{
                    display: inline-block;
                    a {
                        background-color: $bg-footer;
                        color: color-yiq($bg-footer);
                        font-size: $font-size-lg;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        border-radius: $border-radius;
                        border: $border-width solid $border-color;
                        transition: 0.4s;
                        &:hover
                            {
                            background-color: $secondary;
                            color: color-yiq($secondary);
                            border-color: $secondary;
                            transition: 0.4s;
                        }
                    }
                }
            }   
        }
    }
    .copyright-content{
        background-color: $bg-copyright;
        color: color-yiq($bg-copyright);
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 45px;
    
        .footer-image {
            .img-fluid{
                height: 20px;
            }
        }
    
        .footer-info{
            text-align: right;
            a{
                color:color-yiq($bg-copyright);
            }
        }
    } 
    .search-field-module {
        position: relative;
        background-color: $white;
        border:1px solid $border-color;
        box-shadow: none;
        width: 100%;
        &:focus{
            border-color: $secondary;
        }
        

        
            .search-field-wrap{
                float: right;
                height: 42px;
                width: 100%;
                input {
                    float: left;
                    padding: 0px 50px 0 15px;
                    height: 42px;
                    width: 100%;
                    border-radius: 0;
                    border: none;
                    outline: none !important;

                    
                }
                .btn-secondary {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    width: 45px;
                    padding: 0;
                } 
           }   
        .search-field-select{
            float: left;
            position: relative;
            width: 160px !important;
            height: 50px;

            .bootstrap-select{
                width: 100% !important;
                .btn.dropdown-toggle{
                    background-color:$white;
                    color: $body-color-secondary;
                    font-weight: $font-weight-normal;
                    font-family: $font-family-base;
                    height: 50px;
                    width: 100%;
                    border: none;
                    padding: 0;
                    // padding: 0.6rem 4px 0.6rem 15px;
                    .filter-option{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .filter-option-inner-inner {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &:focus{
                        outline: none !important;
                    }

                    &::after{
                        font-family:"Font Awesome 5 Free";
                        font-weight: 900; /* regular style/weight */
                        content: "\f107";
                        position: absolute;
                        top: 17px;
                        right: 6px;
                        color: $body-color-secondary;
                        border: none;
                    }
                    
                }
                
                > .dropdown-menu{
                    animation: animationselect ease 0.7s;
                    animation-iteration-count: 1;
                    transform-origin: 100% 100%;
                    animation-fill-mode: forwards;
                    margin: 0;
                    border: 0;
                    .dropdown-item{
                        padding: 5px 15px 5px 23px;
                    }
                }
                
            }
            &::before {
                content: "";
                width: 1px;
                height: 25px;
                background: $border-color;
                position: absolute;
                top: 50%;
                margin-top: -12px;
                right: -14px;
                z-index: 1;
            }
        }
           
    }
    
} 